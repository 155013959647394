<!-- 日详情 -->
<template>
  <el-dialog
    class="date-detail"
    :title="date"
    :visible.sync="dialogVisible"
    width="30%"
  >
    <div class="button-group" v-if="!completedRecord">
      <el-button
        size="small"
        icon="el-icon-plus"
        @click="showClockInRecordDialog('add')"
      >
        新增
      </el-button>
    </div>
    <el-card
      class="clock-in-record"
      v-for="(record, recordIndex) in data.record"
      :key="recordIndex"
    >
      <div slot="header" class="header">
        <span>{{ record.clock_in_type | clockInTypeFilter }}</span>
        <el-button
          type="danger"
          icon="el-icon-delete"
          @click="showClockInRecordDialog('delete', record)"
          circle
        >
        </el-button>
        <el-button
          type="primary"
          icon="el-icon-edit"
          @click="showClockInRecordDialog('update', record)"
          circle
        ></el-button>
      </div>
      <div>打卡时间：{{ record.clock_in_time }}</div>
    </el-card>
    <template v-if="completedRecord">
      <el-divider> 工时：{{ data.duration }} </el-divider>
    </template>
  </el-dialog>
</template>

<script>
import moment from 'moment'

import ClockInRecord from "./clock-in-record";
import {
  addClockInRecordApi,
  updateClockInRecordApi,
  deleteClockInRecordApi,
} from "@/api/haier/clockInRecord";

const filters = {
  clockInTypeFilter(value) {
    switch (value) {
      case 1:
        return "上班打卡";
      case 2:
        return "下班打卡";
      default:
        return "未知";
    }
  },
};

export default {
  name: "DateDetail",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    date: {
      type: String,
      default: "",
    },
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(value) {
        this.$emit("update:visible", value);
      },
    },
    completedRecord() {
      return Object.keys(this.data.record).length >= 2;
    },
  },
  methods: {
    refresh() {
      this.$emit("refresh");
    },
    showClockInRecordDialog(action, record) {
      if (action === "add") {
        ClockInRecord.add({
          form: {
            clockInTime: moment().format('HH:mm'),
          },
          beforeClose: (action, { clockInTime }, close) => {
            if (action === "confirm") {
              this.addRecord(clockInTime).then(() => {
                close();
              });
            } else {
              close();
            }
          },
        }).catch(() => {});
      } else if (action === "update") {
        ClockInRecord.update({
          form: {
            clockInTime: record.clock_in_time,
          },
          beforeClose: (action, { clockInTime }, close) => {
            if (action === "confirm") {
              this.updateRecord(record, clockInTime).then(() => {
                close();
              });
            } else {
              close();
            }
          },
        }).catch(() => {});
      } else if (action === "delete") {
        this.$confirm(
          `此操作将删除${record.clock_in_date} ${
            record.clock_in_time
          }的${filters.clockInTypeFilter(record.clock_in_type)}记录, 是否继续?`,
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        ).then(() => {
          this.deleteRecord(record.id);
        });
      }
    },
    addRecord(clockInTime) {
      return new Promise((resolve, reject) => {
        addClockInRecordApi({
          clockInDateTime: `${this.date} ${clockInTime}:00`,
        })
          .then(({ data }) => {
            this.refresh();
            if (data.code === 0) {
              this.$notify({
                title: "新增成功",
                message: "新增打卡记录成功!",
                type: "success",
              });
              resolve();
            } else {
              throw new Error(data.msg);
            }
          })
          .catch((err) => {
            console.error(err);
            this.$notify.error({
              title: "请求失败",
              message: "新增打卡记录异常！",
            });
            reject();
          });
      });
    },
    updateRecord(record, clockInTime) {
      return new Promise((resolve, reject) => {
        updateClockInRecordApi({
          id: record.id,
          clockInTime: `${clockInTime}`,
        })
          .then(({ data }) => {
            this.refresh();
            if (data.code === 0) {
              this.$notify({
                title: "修改成功",
                message: "修改打卡记录成功!",
                type: "success",
              });
              resolve();
            } else {
              throw new Error(data.msg);
            }
          })
          .catch((err) => {
            console.error(err);
            this.$notify.error({
              title: "请求失败",
              message: "修改打卡记录异常！",
            });
            reject();
          });
      });
    },
    deleteRecord(id) {
      deleteClockInRecordApi(id)
        .then(({ data }) => {
          this.refresh();
          if (data.code === 0) {
            this.$notify({
              title: "删除成功",
              message: "删除打卡记录成功!",
              type: "success",
            });
          } else {
            throw new Error(data.msg);
          }
        })
        .catch((err) => {
          console.error(err);
          this.$notify.error({
            title: "请求失败",
            message: "删除打卡记录异常！",
          });
        });
    },
  },
  filters,
};
</script>

<style lang="scss" scoped>
.button-group {
  text-align: end;
}
.clock-in-record {
  margin-top: 10px;
  .header {
    .el-button {
      float: right;
      padding: 3px;
    }
    .el-button + .el-button {
      margin: 0 5px 0 0;
    }
  }
}
</style>