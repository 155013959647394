<template>
  <div class="clock-in">
    <!-- 月份统计 -->
    <MonthStatistics :data="currentMonthData"/>
    <!-- 日历 -->
    <el-calendar v-model="currentDate">
      <template slot="dateCell" slot-scope="{ data }">
        <div class="date-cell" @click="showDetail(data.day)">
          <div>
            <span>{{ data.day.substr(8) }}</span>
          </div>
          <div v-if="data.type === 'current-month'" class="clock-in-time">
            <el-tag
              v-for="(clockInOneDay, clockInOneDayIndex) in currentMonthData.records[data.day].record"
              :key="clockInOneDayIndex"
              size="mini"
              >{{ clockInOneDay.clock_in_time }}</el-tag
            >
            <el-tag v-if="currentMonthData.records[data.day].record.length >= 2" size="mini" type="success">
              {{ new Number(currentMonthData.records[data.day].duration).toFixed(2) }}
            </el-tag>
          </div>
        </div>
      </template>
    </el-calendar>

    <!-- 日详情 -->
    <DateDetailVue
      :visible.sync="components.dateDeatil.visable"
      :date="components.dateDeatil.date"
      :data="currentMonthData.records[components.dateDeatil.date]"
      @refresh="getClockInRecord"
    />
  </div>
</template>

<script>
import moment from "moment";

import DateDetailVue from "./date-detail.vue";
import MonthStatistics from "./month-statistics.vue";

import {
  getClockInInfoByMonthApi,
} from "@/api/haier/clockInRecord";

export default {
  name: "ClockIn",
  components: {
    DateDetailVue,
    MonthStatistics,
  },
  watch: {
    currentDate: {
      handler: "getClockInRecord",
      immediate: true,
    },
  },
  computed: {
    currentMonthData() {
      let temp = this.responses[moment(this.currentDate).format("YYYY-MM")]
      if (!temp) {
        temp = {
          clockInDaysCount: 0,
          totalDuration: 0,
          needClockInDaysCount: 0,
          needTotalDuration: 0,
          records: {},
        }
      }
      return temp;
    }
  },
  data() {
    return {
      currentDate: new Date(),
      responses: {},
      components: {
        dateDeatil: {
          visable: false,
          date: null,
        },
      },
    };
  },
  methods: {
    showDetail(date) {
      this.$set(this.components.dateDeatil, "date", date);
      this.$set(this.components.dateDeatil, "visable", true);
    },
    getClockInRecord() {
      let month = moment(this.currentDate).format("YYYY-MM");
      getClockInInfoByMonthApi({ month })
        .then(({ data }) => {
          if (data.code === 0) {
            this.$set(this.responses, month, data.data);
          } else {
            throw new Error(data.msg);
          }
        })
        .catch((err) => {
          console.error(err);
          this.$notify.error({
            title: "请求失败",
            message: "获取打卡记录异常！",
          });
        })
    },
  },
};
</script>

<style lang="scss" scoped>
.clock-in {
  .date-cell {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
    .clock-in-time {
      > .el-tag {
        display: block;
      }
    }
  }
}
</style>