const defaults = {
    title: '',
}

import Vue from 'vue'
import ClockInRecordVue from './main.vue'
import merge from 'element-ui/src/utils/merge';

const ClockInRecordConstructor = Vue.extend(ClockInRecordVue)

let currentOption, instance;
let dialogQueue = [];

const defaultCallback = action => {
    if (currentOption) {
        let callback = currentOption.callback;
        if (typeof callback === 'function') {
            callback(action)
        }
        if (currentOption.resolve) {
            if (action === 'confirm') {
                currentOption.resolve(instance.form)
            } else if (currentOption.reject && ['cancel', 'close'].includes(action)) {
                currentOption.reject(action)
            }
        }
    }
}

const initInstance = () => {
    instance = new ClockInRecordConstructor({
        el: document.createElement('div')
    })
    instance.callback = defaultCallback;
}


const showNexInstance = () => {
    if (!instance) {
        initInstance()
    }
    instance.action = '';
    
    if (!instance.visible) {
        if (dialogQueue.length > 0) {
            currentOption = dialogQueue.shift();

            let options = currentOption.options;
            for (let prop in options) {
                if (options.hasOwnProperty(prop)) { // eslint-disable-line
                  instance[prop] = options[prop];
                }
            }
            if (options.callback === undefined) {
                instance.callback = defaultCallback;
            }
    
            let oldCb = instance.callback;
            instance.callback = (action, instance) => {
                oldCb(action, instance);
                showNexInstance();
            };

            document.body.appendChild(instance.$el);
                Vue.nextTick(() => {
                instance.visible = true;
            });
        }
    }
}

const ClockInRecord = function(options, callback) {
    if (options.callback && !callback) {
        callback = options.callback;
    }
    if (typeof Promise !== 'undefined') {
        return new Promise((resolve, reject) => {
            dialogQueue.push({
                options: merge({}, defaults, options),
                callback: callback,
                resolve,
                reject
            });
            showNexInstance();
        });
    } else {
        dialogQueue.push({
            options: merge({}, defaults, options),
            callback: callback
        });
        showNexInstance();
    }
}

ClockInRecord.setDefaults = defaults => {
    ClockInRecord.defaults = defaults;
};

ClockInRecord.add = (options) => {
    return ClockInRecord(merge({
        title: '新增打卡记录',
        $type: 'add',
        showCancelButton: true,
        form: {
            clockInTime: "",
        },
    }, options));
};

ClockInRecord.update = (options) => {
    console.log(merge({
        title: '新增打卡记录',
        $type: 'add',
        showCancelButton: true,
        form: {
            clockInTime: "",
        },
    }, options))
    return ClockInRecord(merge({
        title: '修改打卡记录',
        $type: 'update',
        showCancelButton: true,
        form: {
            clockInTime: "",
        },
    }, options));
};
export default ClockInRecord;
export { ClockInRecord };
