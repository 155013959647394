<template>
  <el-dialog :title="title" :visible.sync="visible" width="30%">
    <el-form :model="form" :rules="rules" ref="clockInRecordForm">
      <el-form-item label="打卡时间" prop="clockInTime">
        <el-time-picker
          v-model="form.clockInTime"
          placeholder="打卡时间"
          value-format="HH:mm"
          :picker-options="{
            format: 'HH:mm',
          }"
        >
        </el-time-picker>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button
        v-if="showCancelButton"
        size="small"
        @click.native="handleAction('cancel')"
      >
        {{ cancelButtonText }}
      </el-button>
      <el-button
        v-show="showConfirmButton"
        type="primary"
        size="small"
        @click.native="handleAction('confirm')"
      >
        {{ confirmButtonText }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: "ClockInRecord",
  watch: {
    visible(newValue) {
      if (newValue) {
        this.uid++;
        this.$refs["clockInRecordForm"].resetFields();
      }
    },
  },
  data() {
    return {
      uid: 1,
      form: {
        clockInTime: "",
      },
      rules: {
        clockInTime: [
          {
            required: true,
            message: "请选择打卡时间",
            trigger: ["blur", "change"],
          },
        ],
      },
      close: undefined,
      visible: false,
      title: "",
      action: "",
      callback: null,
      showCancelButton: false,
      cancelButtonText: "取 消",
      showConfirmButton: true,
      confirmButtonText: "确 定",
      beforeClose: undefined,
    };
  },
  methods: {
    getSafeClose() {
      const currentId = this.uid;
      return () => {
        this.$nextTick(() => {
          if (currentId === this.uid) this.doClose();
        });
      };
    },
    doClose() {
      this.visible = false;
      this.$nextTick(() => {
        if (this.action) this.callback(this.action, this.form);
      });
    },
    handleAction(action) {
      this.action = action;
      if (typeof this.beforeClose === "function") {
        this.close = this.getSafeClose();
        this.beforeClose(action, this.form, this.close);
      } else {
        this.doClose();
      }
    },
  },
};
</script>

<style>
</style>