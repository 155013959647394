import request from "@/router/axios";

/**
 * 根据月份获取打卡记录
 * 
 * @param { month } params 
 * @returns 
 */
export const getRecordByMonthApi = params => {
    return request({
        url: "/api/haier/clock-in/getRecordByMonth",
        method: "get",
        params
    });
};

/**
 * （新）根据月份获取打卡记录
 * 
 * @param { month } params 
 * @returns 
 */
export const getClockInInfoByMonthApi = params => {
    return request({
        url: "/api/haier/clock-in/getClockInInfoByMonth",
        method: "get",
        params
    });
};

/**
 * 根据日期获取打卡记录
 * 
 * @param { clockInDate } params 
 * @returns 
 */
export const getRecordByDateApi = params => {
    return request({
        url: "/api/haier/clock-in/getRecordByDate",
        method: "get",
        params
    });
};

/**
 * 新增打卡记录
 * 
 * @param { clockInDateTime } data 
 * @returns 
 */
export const addClockInRecordApi = data => {
    return request({
        url: "/api/haier/clock-in/clockIn",
        method: "post",
        data
    });
};

/**
 * 修改打卡记录
 * 
 * @param { clockInTime } data 
 * @returns 
 */
export const updateClockInRecordApi = data => {
    return request({
        url: "/api/haier/clock-in/clockIn",
        method: "put",
        data
    });
};

/**
 * 删除打卡记录
 * 
 * @param Number id 
 * @returns 
 */
export const deleteClockInRecordApi = id => {
    return request({
        url: `/api/haier/clock-in/clockIn/${id}`,
        method: "delete",
    });
};